import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppBar, FeedbackBottomSheet, ServiceNotifications, ThemeProvider } from '../components';
import Router from '../routes';
import { Theme } from '../utils';
import { Provider as ReduxProvider } from 'react-redux';
import { ModalProvider } from '../contexts/ModalContext';
import { store } from '../redux/store';
import { AnalyticsProvider } from '../contexts/AnalyticsContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import { InitialProvider } from '../contexts/InitialContext';
import { AuthProvider } from '../contexts/AuthContext';
import './index.scss';
import { InternetConnectionContextProvider } from '../contexts/InternetConnectionContext';
import { NoServerConectionContextProvider } from '../contexts/NoServerConectionContext';

const App: FC = () => {
  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <InitialProvider>
          <LocalizationProvider adapterLocale={ru} dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={Theme.light}>
              <AnalyticsProvider>
                <AuthProvider>
                  <ModalProvider>
                    <ServiceNotifications />

                    <InternetConnectionContextProvider>
                      <NoServerConectionContextProvider>
                        <AppBar>
                          <Router />
                        </AppBar>
                      </NoServerConectionContextProvider>
                    </InternetConnectionContextProvider>
                    <FeedbackBottomSheet />
                  </ModalProvider>
                </AuthProvider>
              </AnalyticsProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </InitialProvider>
      </ReduxProvider>
    </BrowserRouter>
  );
};

export default App;
