import { useEffect } from 'react';
import { useAppDispatch, useUser } from '.';
import { setIsMobile } from '../redux/device/slice';

export const useDevice = () => {
  const { user } = useUser();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleResize = () => dispatch(setIsMobile(window.matchMedia('(pointer:coarse)').matches));

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch, user]);
};
