import { CardMedia } from '@mui/material';
import cn from 'classnames';
import { FC, useState } from 'react';

import ApiClient from '../../../api/ApiClient';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { IImagePathProps } from '../../../models';
import { getImageByName } from '../../../pages/Stories/model/selectors';
import { setImage, setImageStatus } from '../../../redux/images/slice';
import { getTextError } from '../../../redux/utils';
import { Statuses, getImagePath } from '../../../utils';

import s from './styles.module.scss';

export const TEST_ID = 'image-cover';

interface IProps {
  img: string | IImagePathProps | undefined;
  defaultImg?: string;
  maxZIndexPreloader?: boolean;
  fit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  loading?: 'eager' | 'lazy' | undefined;
  onLoad?: () => void;
}

const ImageCover: FC<IProps> = ({ img, defaultImg, maxZIndexPreloader, fit = 'cover', loading = 'lazy', onLoad }) => {
  const [loadingError, setLoadingError] = useState(false);
  const [isImgUploaded, setIsImgUploaded] = useState(false);

  const isStringImg = typeof img === 'string';

  const imgProps = !isStringImg && img ? img : undefined;

  const nameForStore = imgProps ? `${imgProps.name}/${imgProps.width}/${imgProps.height}` : '';

  const dispatch = useAppDispatch();

  const imageFromStore = useAppSelector(getImageByName(nameForStore));

  const defaultCover =
    !isImgUploaded && (imageFromStore === null || imageFromStore?.statuse === Statuses.failed) && defaultImg
      ? defaultImg
      : undefined;

  const preloadImg =
    !isStringImg && imgProps && imgProps.name && !loadingError && imgProps.name !== ''
      ? getImagePath({ ...imgProps, width: Math.round(imgProps.width / 3), height: Math.round(imgProps.height / 3) })
      : defaultCover;

  const storedImage = imageFromStore?.statuse === Statuses.succeeded ? imageFromStore.data : preloadImg;

  const imageForCover = isStringImg ? img : storedImage;

  const isImg = Boolean(img);

  const onLoadedHandler = async () => {
    setIsImgUploaded(true);

    if (typeof img !== 'string' && img && img.name !== '') {
      const imgUrl = getImagePath(img);

      if (imageFromStore === null) {
        try {
          dispatch(setImageStatus({ name: nameForStore, imageUrl: '', status: Statuses.loading, error: '' }));

          const res = await ApiClient.get<Blob>(imgUrl, {
            responseType: 'blob',
          });

          if (res.data) {
            const imageUrl = URL.createObjectURL(res.data);

            dispatch(setImage({ name: nameForStore, imageUrl }));
            onLoad?.();
          }
        } catch (error) {
          console.error(error);
          dispatch(
            setImageStatus({ name: nameForStore, imageUrl: '', status: Statuses.failed, error: getTextError(error) })
          );
        }
      }
    }
  };

  const onErrorHandler = () => {
    setLoadingError(true);
  };

  return (
    <>
      {isImg && !loadingError && (
        <div className={cn(s.imageCover)} key={imgProps?.name + 'img'}>
          <CardMedia
            data-testid={TEST_ID}
            onLoad={onLoadedHandler}
            onError={onErrorHandler}
            loading={loading}
            className={s.img}
            style={{ objectFit: fit }}
            component="img"
            image={imageForCover}
            alt=""
          />
        </div>
      )}
      {!isImgUploaded && defaultCover && (
        <div className={cn(s.imageCover, { [s.preloadImg]: maxZIndexPreloader && imgProps })} key="defaultImg">
          <CardMedia className={s.img} component="img" image={defaultCover} style={{ objectFit: fit }} alt="" />
        </div>
      )}
      {(loadingError || imageFromStore?.statuse === Statuses.failed) && defaultImg && (
        <div className={cn(s.imageCover, { [s.preloadImg]: maxZIndexPreloader && imgProps })} key="defaultNoImg">
          <CardMedia className={s.img} component="img" image={defaultImg} style={{ objectFit: fit }} alt="" />
        </div>
      )}
    </>
  );
};

export default ImageCover;
