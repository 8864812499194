import { FC } from 'react';

interface IProps {
  active?: boolean;
}

export const NotificationsIcon: FC<IProps> = ({ active }) => {
  return (
    <>
      {active && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.99977 1C6.99977 0.447715 7.44749 0 7.99977 0C8.55206 0 8.99977 0.447715 8.99977 1V1.60883C10.8762 2.02303 12.3466 3.57801 12.5955 5.55447L13.0861 9.4497C13.1909 10.2824 13.5871 11.051 14.2044 11.6196L14.5576 11.9448C14.9181 12.2769 15.0276 12.8014 14.8301 13.25C14.6293 13.7058 14.1783 14 13.6803 14H10.4497C10.2181 15.1411 9.20924 16 7.99976 16C6.79028 16 5.7814 15.1411 5.54977 14H2.31981C1.82132 14 1.36975 13.7059 1.16817 13.25C0.969897 12.8016 1.07909 12.2766 1.43976 11.9445L1.79251 11.6196C2.40982 11.051 2.80598 10.2824 2.91084 9.4497L3.40138 5.55447C3.6504 3.57708 5.12209 2.02158 6.99977 1.60825V1Z"
            fill="#1D1D1D"
          />
        </svg>
      )}

      {!active && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.99977 1V1.60825C5.12209 2.02158 3.6504 3.57708 3.40138 5.55447L2.91084 9.4497C2.80598 10.2824 2.40982 11.051 1.79251 11.6196L1.43976 11.9445C1.07909 12.2766 0.969897 12.8016 1.16817 13.25C1.36975 13.7059 1.82132 14 2.31981 14H5.54977C5.7814 15.1411 6.79028 16 7.99976 16C9.20924 16 10.2181 15.1411 10.4497 14H13.6803C14.1783 14 14.6293 13.7058 14.8301 13.25C15.0276 12.8014 14.9181 12.2769 14.5576 11.9448L14.2044 11.6196C13.5871 11.051 13.1909 10.2824 13.0861 9.4497L12.5955 5.55447C12.3466 3.57801 10.8762 2.02245 8.99977 1.60825V1C8.99977 0.447715 8.55206 0 7.99977 0C7.44749 0 6.99977 0.447715 6.99977 1ZM5.3857 5.80436C5.5074 4.83801 6.14392 4.04699 6.99976 3.69591C7.30878 3.56912 7.64636 3.5 7.99854 3.5C8.57033 3.5 9.10364 3.68332 9.53873 3.99731C9.66895 4.09137 9.79066 4.19714 9.90166 4.3132L9.92648 4.33951C10.2918 4.7318 10.5399 5.23759 10.6113 5.80436L11.1018 9.69959C11.2057 10.5247 11.6499 11.5 12.1499 12H11.9346H10.4497C9.49854 12 8.67895 12.6699 8.48972 13.6021C8.44356 13.8295 8.23933 14 7.99976 14C7.76019 14 7.55595 13.8295 7.50979 13.6021C7.32057 12.6699 6.50097 12 5.54977 12H4.06233H3.84985C4.3427 11.5071 4.7787 10.5525 4.89058 9.73508L4.89517 9.69959L5.3857 5.80436Z"
            fill="#1D1D1D"
          />
        </svg>
      )}
    </>
  );
};
