import { FC } from 'react';

interface IProps {
  active?: boolean;
}

export const WorkoutsIcon: FC<IProps> = ({ active }) => {
  return (
    <>
      {active && (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.5552 3.70348C11.5552 4.37851 10.9583 4.92574 10.2219 4.92574C9.48546 4.92574 8.88849 4.37851 8.88849 3.70348C8.88849 3.02844 9.48546 2.48122 10.2219 2.48122C10.9583 2.48122 11.5552 3.02844 11.5552 3.70348Z"
            fill="#1D1D1D"
            stroke="#1D1D1D"
            strokeWidth="1.33337"
          />
          <path
            d="M10.0978 11.4444L10.9367 10.9995C11.892 10.4929 12.3696 10.2396 12.4342 9.87862C12.4443 9.82222 12.4469 9.76515 12.442 9.70832C12.4108 9.34454 11.9576 9.06418 11.0514 8.50345L6.86723 5.9146C5.22389 4.89782 4.8458 3.04436 6.00062 1.66635"
            stroke="#1D1D1D"
            strokeWidth="1.33337"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.86717 5.9146C4.64488 7.9517 3.55518 13.5894 3.55518 16.3338M6.86717 5.9146C5.22383 4.89782 4.84574 3.04436 6.00056 1.66635M6.86717 5.9146L9.2198 7.37024M10.0978 11.4444L10.9367 10.9995C11.8919 10.4929 12.3696 10.2396 12.4341 9.87862C12.4442 9.82222 12.4468 9.76514 12.442 9.70832C12.4107 9.34454 11.9576 9.06418 11.0513 8.50345L9.2198 7.37024M10.6666 16.3338C9.25093 14.8197 8.11658 13.0854 7.09706 11.2923M9.2198 7.37024C8.5062 8.21694 8.01432 9.01681 7.6465 9.72856C7.25628 10.4837 7.06117 10.8612 7.09706 11.2923M7.09706 11.2923L4.44409 10.6296"
            stroke="#1D1D1D"
            strokeWidth="1.7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            x="7.00287"
            y="6.47603"
            width="2.10968"
            height="4.61567"
            transform="rotate(30.4064 7.00287 6.47603)"
            fill="#1D1D1D"
          />
        </svg>
      )}

      {!active && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.3368 3.03282C12.3368 4.04812 11.5137 4.87118 10.4984 4.87118C9.48315 4.87118 8.6601 4.04812 8.6601 3.03282C8.6601 2.01753 9.48315 1.19447 10.4984 1.19447C11.5137 1.19447 12.3368 2.01753 12.3368 3.03282Z"
            fill="#1D1D1D"
          />
          <path
            d="M6.55718 4.88784C4.51451 6.7603 3.51288 11.9423 3.51288 14.4649M6.55718 4.88784C5.04666 3.95324 4.90867 2.3157 5.97016 1.04907M6.55718 4.88784L9.53105 6.71187M10.8164 10.4337C10.8164 10.4337 12.544 9.87036 12.4856 9.01751C12.4377 8.3191 11.4746 7.9144 11.2145 7.75349L9.53105 6.71187M10.861 14.9509C9.55967 13.5592 8.51701 11.9651 7.57989 10.3169M9.53105 6.71187C8.83817 7.53399 7.48166 9.13721 7.57989 10.3169M7.57989 10.3169L4.32995 9.22176"
            stroke="#1D1D1D"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};
