import { ReactNode, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import s from './styles.module.scss';
import { Box, BoxProps } from '@mui/material';

export const TEST_ID = 'SliderCards';

const skeletons = [1, 2];

export type TRenderCard<T> = (params: T) => ReactNode;

export type TSkeleton = () => ReactNode;

interface IProps<T> extends BoxProps {
  options: T[];
  swiperPadding?: string;
  renderCard: TRenderCard<T>;
  skeleton?: TSkeleton;
  loading?: boolean;
  renderLimit?: number;
}

function SliderCards<T>({ options, swiperPadding, renderCard, skeleton, loading, renderLimit, ...props }: IProps<T>) {
  const renderOptions = [...options];

  if (renderLimit && options.length > renderLimit) renderOptions.length = renderLimit;

  return (
    <Box flexShrink={0} className={s.sliderCards} {...props}>
      <Swiper
        style={{ padding: swiperPadding, overflow: 'visible' }}
        slidesPerView="auto"
        spaceBetween={8}
        watchOverflow={true}
        freeMode
        resistanceRatio={0}
      >
        {!loading &&
          options.map((course, index) => (
            <SwiperSlide style={{ width: '57%' }} key={index}>
              {Boolean(renderCard) && renderCard(course)}
            </SwiperSlide>
          ))}

        {loading && skeletons.map((skel) => <SwiperSlide key={skel}>{Boolean(skeleton) && skeleton?.()}</SwiperSlide>)}
      </Swiper>
    </Box>
  );
}

export default SliderCards;
