import { FC } from 'react';

interface IProps {
  active?: boolean;
}

export const HomeIcon: FC<IProps> = ({ active }) => {
  return (
    <>
      {active && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.10483 13.8476V11.8031C6.10482 11.2831 6.52877 10.8605 7.05403 10.8571H8.97808C9.50585 10.8571 9.93369 11.2806 9.93369 11.8031V13.8539C9.93357 14.2955 10.2895 14.6563 10.7354 14.6667H12.0181C13.2968 14.6667 14.3334 13.6404 14.3334 12.3745V6.55855C14.3265 6.06055 14.0903 5.59289 13.692 5.28868L9.30517 1.7902C8.53664 1.18104 7.44416 1.18104 6.67563 1.7902L2.30804 5.29503C1.90819 5.59801 1.67161 6.06644 1.66669 6.5649V12.3745C1.66669 13.6404 2.70327 14.6667 3.98196 14.6667H5.26466C5.72159 14.6667 6.092 14.3 6.092 13.8476"
            fill="#1D1D1D"
          />
        </svg>
      )}

      {!active && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.10483 13.8476V11.8031C6.10482 11.2831 6.52877 10.8605 7.05403 10.8571H8.97808C9.50585 10.8571 9.93369 11.2806 9.93369 11.8031V11.8031V13.8539C9.93357 14.2955 10.2895 14.6563 10.7354 14.6667H12.0181C13.2968 14.6667 14.3334 13.6404 14.3334 12.3745V12.3745V6.55855C14.3265 6.06055 14.0903 5.59289 13.692 5.28868L9.30517 1.7902C8.53664 1.18104 7.44416 1.18104 6.67563 1.7902L2.30804 5.29503C1.90819 5.59801 1.67161 6.06644 1.66669 6.5649V12.3745C1.66669 13.6404 2.70327 14.6667 3.98196 14.6667H5.26466C5.72159 14.6667 6.092 14.3 6.092 13.8476V13.8476"
            stroke="#1D1D1D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};
