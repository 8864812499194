import { FC } from 'react';

interface IProps {
  collapse: boolean;
}

export const Logo: FC<IProps> = ({ collapse }) => {
  return (
    <svg width="128" height="24" viewBox="0 0 128 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M112.473 20.2125L111.193 17.5636H101.811L100.553 20.2125H94.1953L102.319 3.78906H110.751L119.338 20.2125H112.473ZM103.732 13.4577H109.184L106.425 7.80662L103.732 13.4577Z"
        fill="#1D1D1D"
        opacity={collapse ? 0 : 1}
        style={{ transition: 'opacity 0.3s' }}
      />
      <path
        d="M94.9902 14.9385C94.9902 17.7199 93.4449 20.5234 84.8138 20.5234H82.805C73.7324 20.5234 72.7832 16.8369 72.7832 14.6957V14.4749H79.6704C79.7367 15.3579 80.2002 16.7928 83.7763 16.7928H83.975C87.4848 16.7928 87.9925 16.0422 87.9925 15.2475V15.2255C87.9925 14.4087 87.5952 13.8348 84.1295 13.7465L81.0832 13.6582C74.7258 13.4595 73.026 11.2741 73.026 8.71349V8.51482C73.026 5.8438 74.8361 3.50391 82.4739 3.50391H84.4385C92.562 3.50391 94.4162 6.08662 94.4162 8.69142V8.86801H87.5952C87.529 8.272 87.1758 7.12412 83.6218 7.12412H83.401C80.1119 7.12412 79.7808 7.69806 79.7808 8.33822V8.3603C79.7808 9.02253 80.2665 9.5744 83.3569 9.61855L86.3149 9.68477C92.562 9.83929 94.9902 11.3404 94.9902 14.6295V14.9385Z"
        fill="#1D1D1D"
        opacity={collapse ? 0 : 1}
        style={{ transition: 'opacity 0.3s' }}
      />
      <path
        d="M54.4121 3.78906H60.6813V15.5106H72.0717V20.2125H54.4121V3.78906Z"
        fill="#1D1D1D"
        opacity={collapse ? 0 : 1}
        style={{ transition: 'opacity 0.3s' }}
      />
      <path
        d="M40.0216 3.48047H41.015C51.6107 3.48047 52.9794 8.51346 52.9794 11.626V12.2882C52.9794 15.3566 51.6328 20.522 41.015 20.522H40.0216C29.3817 20.522 28.0352 15.3566 28.0352 12.2882V11.626C28.0352 8.51346 29.3817 3.48047 40.0216 3.48047ZM46.4894 11.7805C46.4894 10.0366 45.3857 7.89537 40.5073 7.89537C35.5626 7.89537 34.5251 10.0366 34.5251 11.7805V12.0454C34.5251 13.7672 35.6509 16.085 40.5073 16.085C45.3636 16.085 46.4894 13.8334 46.4894 12.0895V11.7805Z"
        fill="#1D1D1D"
        opacity={collapse ? 0 : 1}
        style={{ transition: 'opacity 0.3s' }}
      />
      <path
        d="M22.2822 20.2125L14.2692 12.8617V20.2125H8V3.78906H14.2692V10.279L22.3484 3.78906H30.1407L20.5825 11.2502L30.4498 20.2125H22.2822Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};
