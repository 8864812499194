import { FC } from 'react';

interface IProps {
  active?: boolean;
}

export const ReferalIcon: FC<IProps> = ({ active }) => {
  return (
    <>
      {active && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_292_3664)">
            <path
              d="M11.503 1.22924C11.5987 0.80163 11.4049 0.361501 11.0249 0.14341C10.6448 -0.0746807 10.167 -0.019925 9.84613 0.278496L2.31901 7.27891C2.01801 7.55886 1.91897 7.99449 2.06938 8.37705C2.21978 8.75962 2.58897 9.01117 3.00004 9.01117H5.67225L4.38775 14.7858C4.29288 15.2123 4.48586 15.651 4.86435 15.8692C5.24283 16.0875 5.71918 16.0348 6.04078 15.739L13.6635 8.72934C13.9668 8.4504 14.068 8.01394 13.9183 7.63C13.7686 7.24605 13.3987 6.99326 12.9866 6.99326H10.2125L11.503 1.22924Z"
              fill="#1D1D1D"
            />
          </g>
          <defs>
            <clipPath id="clip0_292_3664">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}

      {!active && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_292_3680)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.9836 0.120986C11.3801 0.324381 11.5941 0.765575 11.5085 1.20288L10.5668 6.01281H12.9866C13.3867 6.01281 13.7484 6.25139 13.9059 6.61928C14.0633 6.98718 13.9863 7.41353 13.7101 7.70308L6.08742 15.6932C5.78083 16.0146 5.29901 16.0952 4.9045 15.8912C4.50998 15.6871 4.29732 15.2473 4.38239 14.8114L5.31822 10.0158H3.00002C2.60115 10.0158 2.24043 9.77876 2.08214 9.41264C1.92385 9.04653 1.99827 8.62136 2.2715 8.33077L9.79861 0.325715C10.1039 0.00107852 10.5871 -0.0824095 10.9836 0.120986ZM5.31296 8.01579H6.53223C6.83101 8.01579 7.11413 8.14939 7.30409 8.38C7.49405 8.61061 7.57094 8.91408 7.51372 9.20733L7.00014 11.8391L10.6505 8.01281H9.35198C9.0531 8.01281 8.76988 7.87912 8.57993 7.64836C8.38997 7.41761 8.31319 7.11398 8.37062 6.82067L8.87919 4.22312L5.31296 8.01579Z"
              fill="#1D1D1D"
            />
          </g>
          <defs>
            <clipPath id="clip0_292_3680">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};
