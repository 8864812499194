import TabUnstyled from '@mui/base/TabUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import cn from 'classnames';
import { Skeleton, Typography } from 'front-package-ui-kit';
import { useEffect } from 'react';

import s from './styles.module.scss';

export const TEST_ID = 'tab-buttons';

const skeletons = [1, 2];

interface IProps<T> {
  options: T[];
  onChange: (tabValue: T) => void;
  value: T;
  variant?: 'contained' | 'transparent';
  loading?: boolean;
}

export const TabButtons = <T extends string | number>({
  options,
  onChange,
  value,
  loading,
  variant = 'transparent',
}: IProps<T>) => {
  const tabBtnContainerClasses = cn(s.tabButtons, { [s.tabButtonsContained]: variant === 'contained' });

  const onTabChange = (tabValue: T) => () => {
    onChange(tabValue);
  };

  return (
    <TabsListUnstyled data-testid={TEST_ID} className={tabBtnContainerClasses}>
      {!loading &&
        options.map((val) => (
          <TabUnstyled
            key={val}
            className={cn(s.tabBtn, { [s.tabBtnActive]: value === val })}
            value={val}
            onChange={onTabChange(val)}
          >
            <Typography variant="text6" fontWeight={500} color="black" opacity={value === val ? 1 : 0.6}>
              {val}
            </Typography>
          </TabUnstyled>
        ))}

      {loading &&
        skeletons.map((skeleton) => (
          <div key={skeleton} className={cn(s.tabBtn, s.skeletonContainer)}>
            <Skeleton width="56%" height="14px" borderRadius="4px" />
          </div>
        ))}
    </TabsListUnstyled>
  );
};
