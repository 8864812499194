import { FC, ReactNode, memo } from 'react';
import s from './styles.module.scss';
import { Box, Stack } from '@mui/material';
import MobileNavigationAction from './MobileNavigationAction';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks';
import cn from 'classnames';
import { mobileNavigationOptions } from '../../../../../utils';

export const TEST_ID = 'MobileNavigation';

export interface MobileNavigationProps {
  children: ReactNode;
}

const MobileNavigation: FC<MobileNavigationProps> = ({ children }) => {
  const { pathname } = useLocation();

  const { theme } = useAppSelector((state) => state.themeStore);

  const isShowMobileAppbar = mobileNavigationOptions.some((option) => pathname.includes(option.path));

  return (
    <>
      {isShowMobileAppbar && (
        <Stack direction="column" className={s.mobileNavigation} data-testid={TEST_ID}>
          <Box className={s.body} flexGrow={1}>
            {children}
          </Box>

          <Stack
            justifyContent="space-around"
            alignItems="center"
            direction="row"
            className={cn(s.actionsContainer, { [s.actionsContainerDark]: theme === 'dark' })}
          >
            {mobileNavigationOptions.map(({ label, path, Icon }) => (
              <MobileNavigationAction
                theme={theme}
                key={label}
                isActive={pathname.includes(path)}
                Icon={Icon}
                label={label}
                path={path}
              />
            ))}
          </Stack>
        </Stack>
      )}

      {!isShowMobileAppbar && children}
    </>
  );
};

export const MobileNavigationMemo = memo(MobileNavigation);
