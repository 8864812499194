import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IDeviceStore {
  isMobile: boolean | null;
}

export const initialState: IDeviceStore = {
  isMobile: null,
};

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setIsMobile: (state, { payload }: PayloadAction<boolean>) => {
      state.isMobile = payload;
    },
  },
});

export const { setIsMobile } = deviceSlice.actions;

export default deviceSlice.reducer;
