import React from 'react';
import { FC } from 'react';
import { IMobileNavigationIconProps } from '../../../models';

interface IProps extends IMobileNavigationIconProps {}

const ProfileIconNav: FC<IProps> = ({ isActive, theme = 'light' }) => {
  return (
    <>
      {!isActive && (
        <>
          {theme === 'light' && (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1566 15.3814C8.0268 15.3814 4.5 16.0058 4.5 18.5065C4.5 21.0072 8.00442 21.654 12.1566 21.654C16.2865 21.654 19.8123 21.0286 19.8123 18.5289C19.8123 16.0292 16.3089 15.3814 12.1566 15.3814Z"
                stroke="#818181"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1548 11.8146C14.8649 11.8146 17.0616 9.61699 17.0616 6.9068C17.0616 4.19662 14.8649 2 12.1548 2C9.44457 2 7.24694 4.19662 7.24694 6.9068C7.23778 9.60783 9.42017 11.8055 12.1202 11.8146H12.1548Z"
                stroke="#818181"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}

          {theme === 'dark' && (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1566 15.3814C8.0268 15.3814 4.5 16.0058 4.5 18.5065C4.5 21.0072 8.00442 21.654 12.1566 21.654C16.2865 21.654 19.8123 21.0286 19.8123 18.5289C19.8123 16.0292 16.3089 15.3814 12.1566 15.3814Z"
                stroke="#818181"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1548 11.8146C14.8649 11.8146 17.0616 9.61699 17.0616 6.9068C17.0616 4.19662 14.8649 2 12.1548 2C9.44457 2 7.24694 4.19662 7.24694 6.9068C7.23778 9.60783 9.42017 11.8055 12.1202 11.8146H12.1548Z"
                stroke="#818181"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </>
      )}

      {isActive && (
        <>
          {theme === 'light' && (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0006 14.8027C7.14584 14.8027 3 15.5367 3 18.4763C3 21.416 7.11955 22.1763 12.0006 22.1763C16.8553 22.1763 21 21.4411 21 18.5026C21 15.5642 16.8816 14.8027 12.0006 14.8027Z"
                fill="#1D1D1D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9984 12.9609C15.1843 12.9609 17.7665 10.3775 17.7665 7.19162C17.7665 4.00572 15.1843 1.42353 11.9984 1.42353C8.81248 1.42353 6.2291 4.00572 6.2291 7.19162C6.21834 10.3667 8.78379 12.9501 11.9577 12.9609H11.9984Z"
                fill="#1D1D1D"
              />
            </svg>
          )}

          {theme === 'dark' && (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0006 14.8027C7.14584 14.8027 3 15.5367 3 18.4763C3 21.416 7.11955 22.1763 12.0006 22.1763C16.8553 22.1763 21 21.4411 21 18.5026C21 15.5642 16.8816 14.8027 12.0006 14.8027Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9984 12.9609C15.1843 12.9609 17.7665 10.3775 17.7665 7.19162C17.7665 4.00572 15.1843 1.42353 11.9984 1.42353C8.81248 1.42353 6.2291 4.00572 6.2291 7.19162C6.21834 10.3667 8.78379 12.9501 11.9577 12.9609H11.9984Z"
                fill="white"
              />
            </svg>
          )}
        </>
      )}
    </>
  );
};

export default ProfileIconNav;
