import { Button, KeyboardArrowDownIcon, Typography } from 'front-package-ui-kit';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useOpenSingleFilterBottomSheet } from '../../../hooks';
import { IButtonsFilterOptions } from '../../../hooks/useFilter';
import { upperCaseFirst } from '../../../utils';
import CloseIcon from './CloseIcon';
import { Box } from '@mui/material';
import s from './styles.module.scss';

export const TEST_ID = 'QuickFilterItem';

interface IProps {
  filterOptions: IButtonsFilterOptions;
  prefilledFilterValue?: string;
  setActiveFilterOptionsTitleForOrder: (filterOptionsTitle: string) => void;
}

const QuickFilterItem: FC<IProps> = ({ filterOptions, prefilledFilterValue, setActiveFilterOptionsTitleForOrder }) => {
  const { filterValues, title, filterButtons, onFilterButtonClickHandler, clearFilterHandler } = filterOptions;

  const { handleOpenSingleFilterBottomSheet } = useOpenSingleFilterBottomSheet();

  const [singleFilterButton] = filterButtons ?? [];

  const isSingleFilter = filterButtons?.length === 1;

  const isActive = filterValues?.length && filterButtons?.find(({ value }) => filterValues?.includes(value));

  const activeValues = filterValues?.filter((filterValue) => filterButtons?.find(({ value }) => filterValue === value));

  useEffect(() => {
    const prefilledFilterButton = filterButtons?.find(({ value }) => value === prefilledFilterValue);

    if (!prefilledFilterButton || !prefilledFilterValue) {
      return;
    }

    if (filterValues?.includes(prefilledFilterValue)) {
      setActiveFilterOptionsTitleForOrder(title);

      return;
    }
  }, [filterButtons, filterValues, prefilledFilterValue, setActiveFilterOptionsTitleForOrder, title]);

  const handleClearFilter = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();

      clearFilterHandler();
    },
    [clearFilterHandler]
  );

  const quickFilterItemTitle = useMemo(() => {
    const [firstActiveValue] = activeValues;

    return filterButtons?.find(({ value }) => firstActiveValue === value)?.name ?? title ?? '';
  }, [activeValues, filterButtons, title]);

  return (
    <>
      {!isSingleFilter && (
        <Button
          size="S"
          color={isActive ? 'black' : 'grey20'}
          onClick={handleOpenSingleFilterBottomSheet(title)}
          variant={isActive ? 'contained' : 'outlined'}
          endIcon={
            isActive ? (
              <Box width="16px" height="16px" onClick={handleClearFilter}>
                <CloseIcon />
              </Box>
            ) : (
              <KeyboardArrowDownIcon size={16} />
            )
          }
        >
          <Typography color={isActive ? 'white' : 'black'} whiteSpace="nowrap" variant="text6" fontWeight={500}>
            {upperCaseFirst(quickFilterItemTitle)}
            {activeValues.length > 1 && ` +${activeValues.length - 1}`}
          </Typography>
        </Button>
      )}

      {isSingleFilter && (
        <Button
          variant={
            filterValues.includes(String(singleFilterButton?.value)) && !singleFilterButton?.disabled
              ? 'contained'
              : 'outlined'
          }
          disabled={singleFilterButton?.disabled}
          onClick={onFilterButtonClickHandler(String(singleFilterButton?.value))}
          size="S"
          color={
            filterValues.includes(String(singleFilterButton?.value)) && !singleFilterButton?.disabled
              ? 'black'
              : 'grey20'
          }
          endIcon={
            isActive && (
              <Box width="16px" height="16px" onClick={handleClearFilter}>
                <CloseIcon />
              </Box>
            )
          }
        >
          <Typography
            color={
              filterValues.includes(String(singleFilterButton?.value)) && !singleFilterButton?.disabled
                ? 'white'
                : 'black'
            }
            whiteSpace="nowrap"
            variant="text6"
            fontWeight={500}
          >
            {upperCaseFirst(singleFilterButton?.name ?? '')}
          </Typography>
        </Button>
      )}
    </>
  );
};

export default QuickFilterItem;
