import { FC, ReactNode, memo } from 'react';
import { MobileNavigation } from './components/mobile';
import { DesktopNavigation } from './components/desktop';
import { useAppSelector } from '../../../hooks';

export const TEST_ID = 'AppBar';

export interface AppBarProps {
  children?: ReactNode;
}

const AppBar: FC<AppBarProps> = ({ children }) => {
  const { isMobile } = useAppSelector((state) => state.deviceStore);

  return (
    <>
      {!isMobile && <DesktopNavigation>{children}</DesktopNavigation>}

      {isMobile && <MobileNavigation>{children}</MobileNavigation>}
    </>
  );
};

export const AppBarMemo = memo(AppBar);
