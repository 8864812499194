import React, { FC, ReactNode, memo, useCallback, useState } from 'react';
import s from './styles.module.scss';
import {
  CoursesIcon,
  FavoritesIcon,
  HomeIcon,
  Logo,
  NotificationsIcon,
  ReferalIcon,
  SettingsIcon,
  SupportIcon,
  WorkoutsIcon,
} from '../../icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { ImagePaths, Paths, ResizeType } from '../../../../../utils';
import { useAppDispatch, useAppSelector, useUser } from '../../../../../hooks';
import { ProfileTabButtons } from '../../../../../pages/Profile/config';
import { setCurrentTab } from '../../../../../pages/Profile/slice';
import { ImageCover } from '../../../..';
import defaultCover from '../../../../../assets/image/defaultCover.png';
import { ProfileIcon } from '../../../../../assets/icons';
import { KeyboardArrowLeftIcon, NamesOfColors, Typography } from 'front-package-ui-kit';
import cn from 'classnames';

export const TEST_ID = 'DesktopNavigation';

enum ModalNames {
  notifications = 'notifications',
  support = 'support',
}

export interface DesktopNavigationProps {
  children: ReactNode;
}

const DesktopNavigation: FC<DesktopNavigationProps> = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const navigate = useNavigate();

  const [modal, setModal] = useState('');

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const profileCurrentTab = useAppSelector((state) => state.profileStore.currentTab);

  const { user } = useUser();

  const isActiveHandler = (path: string) => {
    return pathname.includes(path) && !modal;
  };

  const bodyMenuOptions = [
    {
      path: Paths.profile,
      label: 'Профиль',
      image: user?.avatar,
      isActive: (path: string) => isActiveHandler(path) && profileCurrentTab !== ProfileTabButtons.favorite,
      onClick: () => {
        if (modal) setModal('');
        dispatch(setCurrentTab(ProfileTabButtons.progress));
        navigate(Paths.profile);
      },
    },
    { path: Paths.main, label: 'Главная', Icon: HomeIcon, isActive: isActiveHandler },
    { path: Paths.singleWorkouts, label: 'Тренировки', Icon: WorkoutsIcon, isActive: isActiveHandler },
    { path: Paths.courses, label: 'Программы', Icon: CoursesIcon, isActive: isActiveHandler },
    {
      path: Paths.profile,
      label: 'Избранное',
      Icon: FavoritesIcon,
      isActive: (path: string) => isActiveHandler(path) && profileCurrentTab === ProfileTabButtons.favorite,
      onClick: () => {
        if (modal) setModal('');
        dispatch(setCurrentTab(ProfileTabButtons.favorite));
        navigate(Paths.profile);
      },
    },
    {
      path: Paths.profile,
      label: 'Поддержка',
      Icon: SupportIcon,
      isActive: () => modal === ModalNames.support,
      onClick: () => {
        setModal(ModalNames.support);
      },
    }, //support открывется телеграмм, ссылка приходит из констант
    {
      path: Paths.notifications,
      label: 'Уведомления',
      Icon: NotificationsIcon,
      isActive: () => modal === ModalNames.notifications,
      onClick: () => {
        setModal(ModalNames.notifications);
      },
    },
  ];

  const bottomMenuOptions = [
    { path: Paths.settings, label: 'Настройки', Icon: SettingsIcon, isActive: isActiveHandler },
    { path: Paths.referralProgramme, label: 'Пригласи друга', Icon: ReferalIcon, isActive: isActiveHandler },
  ];

  const isShowAppbar = [...bodyMenuOptions, ...bottomMenuOptions].some((option) =>
    option.path ? pathname.includes(option.path) : false
  );

  const handleMenuBtnClick = useCallback(
    (path: string) => () => {
      navigate(path);

      if (modal) setModal('');
    },
    [modal, navigate]
  );
  return (
    <>
      {isShowAppbar && (
        <div className={s.desktopNavigation} data-testid={TEST_ID}>
          <div className={s.container}>
            <aside className={cn(s.menu, { [s.menuCollapsed]: isCollapsed })}>
              <div className={s.logo}>
                <Logo collapse={isCollapsed} />
              </div>

              <div className={s.menuBtnContainer}>
                <div className={s.menuBtnContainerBody}>
                  {bodyMenuOptions.map(({ path, label, Icon, image, isActive, onClick }) => (
                    <button
                      onClick={onClick ? onClick : handleMenuBtnClick(path)}
                      className={cn(s.menuBtn, { [s.menuBtnActive]: isActive(path) })}
                      key={label}
                    >
                      {!!image && (
                        <div className={s.avatar}>
                          {Boolean(user?.avatar) && (
                            <ImageCover
                              img={{
                                baseApiService: 'BASE',
                                imagePath: ImagePaths.clientAvatar,
                                name: image,
                                width: 192,
                                height: 192,
                                resize: ResizeType.none,
                              }}
                              defaultImg={defaultCover}
                            />
                          )}

                          {!Boolean(user?.avatar) && <ProfileIcon size="24" />}
                        </div>
                      )}

                      {!!Icon && (
                        <div className={s.iconContainer}>
                          <Icon />
                        </div>
                      )}

                      <div className={cn(s.menuBtnLabel, { [s.menuBtnLabelCollapsed]: isCollapsed })}>
                        <Typography whiteSpace="nowrap" variant="text6" fontWeight={500}>
                          {label}
                        </Typography>
                      </div>
                    </button>
                  ))}
                </div>

                {bottomMenuOptions.map(({ path, label, Icon, isActive }) => (
                  <button
                    onClick={handleMenuBtnClick(path)}
                    className={cn(s.menuBtn, { [s.menuBtnActive]: isActive(path) })}
                    key={label}
                  >
                    <div className={s.iconContainer}>
                      <Icon />
                    </div>

                    <div className={cn(s.menuBtnLabel, { [s.menuBtnLabelCollapsed]: isCollapsed })}>
                      <Typography whiteSpace="nowrap" variant="text6" fontWeight={500}>
                        {label}
                      </Typography>
                    </div>
                  </button>
                ))}

                <button className={s.collapseBtn} onClick={() => setIsCollapsed((prev) => !prev)}>
                  <div className={cn(s.iconContainer, s.collapseBtnIcon, { [s.collapseBtnCollapsed]: isCollapsed })}>
                    <KeyboardArrowLeftIcon
                      size={16}
                      color={isCollapsed ? NamesOfColors.grey100 : NamesOfColors.grey30}
                    />
                  </div>
                </button>
              </div>
            </aside>

            <main className={s.main} style={{ width: `calc(100% - ${isCollapsed ? 68 : 200}px)` }}>
              {children}
            </main>
          </div>
        </div>
      )}

      {!isShowAppbar && children}
    </>
  );
};

export const DesktopNavigationMemo = memo(DesktopNavigation);
