import React, { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import api from '../../api';
import { setBackUrls } from '../../api/config';
import { setSession } from '../../api/jwt';
import { SplashScreen } from '../../components';
import { useAppDispatch, useAppSelector, useDevice } from '../../hooks';
import { IDeviceInfo } from '../../models';
import { getNotificationsThunk } from '../../redux/notifications/thunks';
import {
  Environment,
  ErrorMessages,
  getIsNeedOnboarding,
  getUserInfo,
  noticeCreator,
  Paths,
  setInitMetrika,
} from '../../utils';
import { useUpdateEffect } from 'usehooks-ts';
import { createServiceNotice } from '../../redux/notifications/slice';
import { getCoursesThunk } from '../../redux/courses/thunks';
import { getRenderSchemesThunk } from '../../redux/renderSchemes/thunks';

export const TEST_ID = 'InitialContext';

const { onboarding } = Paths;

interface IInitialContext {
  isAuthenticated: boolean;
  isRequiredUpdate: boolean;
  currentPlatform: 'android' | 'ios' | 'web' | '';
  updateLink: string;
  currentDeviceInfo: IDeviceInfo;
}

const InitialContext = createContext<IInitialContext | null>(null);

interface IProps {
  children: ReactNode;
}

const { needUpdate } = Paths;

const InitialProvider: FC<IProps> = ({ children }) => {
  const [isGetTokensCompleted, setIsGetTokensCompleted] = useState(false);

  const [isGetBackUrlCompleted, setIsGetBackUrlCompleted] = useState(false);

  const [isGetVersionCompleted, setIsGetVersionCompleted] = useState(false);

  const [isGetPlatformCompleted, setIsGetPlatformCompleted] = useState(false);

  const [isGetDeviceInfo, setIsGetDeviceInfo] = useState(false);

  const token = useAppSelector((state) => state.authStore.access_token);

  const [currentPlatform, setCurrentPlatform] = useState<'android' | 'ios' | 'web' | ''>('');

  const [isRequiredUpdate, setIsRequiredUpdate] = useState(false);

  const [updateLink, setUpdateLink] = useState('');

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [currentDeviceInfo, setCurrentDeviceInfo] = useState<IDeviceInfo>({ os: '', model: '', size: '' });

  const navigate: NavigateFunction = useNavigate();

  const dispatch = useAppDispatch();

  const html = document.querySelector('html');

  useDevice();

  const getVersions = async () => {
    try {
      const versionsByAllPlatforms = (await api.getVersions()).data.data;

      const platform = await window?.getPlatform();

      const versionsByCurrentPlatforms = versionsByAllPlatforms.filter((version) => version.platform === platform);

      return versionsByCurrentPlatforms;
    } catch (error) {
      console.error(error);
    }
  };

  const backUrlInitialize = async () => {
    try {
      const base = await window?.getBackUrl?.('BASE');
      const video = await window?.getBackUrl?.('VIDEO');
      const analytics = await window?.getBackUrl?.('ANALYTICS');
      const notification = await window?.getBackUrl?.('NOTIFICATION');
      const app = await window?.getBackUrl?.('APP');
      const comments = await window?.getBackUrl?.('COMMENTS');

      if (!base || !video || !analytics || !app || !notification || !comments) {
        dispatch(
          createServiceNotice({
            notice: noticeCreator(ErrorMessages.getBackUrl, 'error'),
            otherInfo: {
              error: { errorText: 'One of BackUrl undefined', base, video, analytics, app, notification },
              pathname: 'getBackUrl',
              forEnvironment: Environment.development,
            },
          })
        );

        return;
      }

      setBackUrls({ base, video, analytics, app, notification, comments });
    } catch (error) {
      dispatch(
        createServiceNotice({
          notice: noticeCreator(ErrorMessages.getBackUrl, 'error'),
          otherInfo: { error, pathname: 'getBackUrl', forEnvironment: Environment.development },
        })
      );
    }

    setIsGetBackUrlCompleted(true);
  };

  const authInitialize = async () => {
    const tokensStringify = await window?.getTokens();

    if (!tokensStringify) {
      setIsAuthenticated(false);

      setIsGetTokensCompleted(true);

      return;
    }

    setIsAuthenticated(true);

    const tokens: ITokens = JSON.parse(tokensStringify);

    await setSession(tokens);

    dispatch(getNotificationsThunk());

    const user = await getUserInfo();

    await dispatch(getCoursesThunk({ trusted: user?.trusted ?? false }));

    await dispatch(getRenderSchemesThunk());

    if (user) setInitMetrika(user);

    if (tokensStringify) {
      const isNeedOnboarding = await getIsNeedOnboarding(user);

      if (isNeedOnboarding === true) {
        navigate(onboarding);
      }
    }

    setIsGetTokensCompleted(true);
  };

  const versionInitialize = async () => {
    const versions = await getVersions();

    const isChechWebUpdate = window.checkWebUpdate?.();

    if (!versions || isChechWebUpdate === false) {
      setIsGetVersionCompleted(true);

      return;
    }

    const currentVersion = await window?.getVersion();

    const [latestVersion] = versions;

    if (latestVersion && latestVersion.version > currentVersion) {
      const installationSource = await window?.getInstallationSource();

      setUpdateLink(latestVersion?.link ?? installationSource);

      versions.forEach(({ version, required_update }) => {
        if (version > currentVersion && required_update === true) {
          setIsRequiredUpdate(true);
        }
      });

      navigate(needUpdate);
    }

    setIsGetVersionCompleted(true);
  };

  const platformInitialize = async () => {
    const platform = await window?.getPlatform();

    if (html !== null) html.setAttribute('data-platform', platform);

    try {
      const platformVersion = (await window.getPlatformVersion?.()) ?? '';

      if (html !== null) html.setAttribute('data-platform-version', platformVersion);
    } catch (error) {
      dispatch(
        createServiceNotice({
          notice: noticeCreator(ErrorMessages.getPlatformVersion, 'error'),
          otherInfo: { error, pathname: 'getBackUrl', forEnvironment: Environment.development },
        })
      );
    }

    setCurrentPlatform(platform);

    setIsGetPlatformCompleted(true);
  };

  const deviceInfoInitialize = async () => {
    const deviceInfo = await window?.getDeviceInfo();

    setCurrentDeviceInfo(deviceInfo);

    setIsGetDeviceInfo(true);
  };

  useEffect(() => {
    window.navigate = navigate;

    (async () => {
      await backUrlInitialize();

      await platformInitialize();

      await deviceInfoInitialize();

      await versionInitialize();

      await authInitialize();

      if (html !== null) html.setAttribute('data-initialization-completed', 'true');

      window.loaded();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUpdateEffect(() => {
    (async () => {
      const tokensStringify = await window?.getTokens();

      if (!tokensStringify) {
        setIsAuthenticated(false);

        return;
      }

      const tokens: ITokens = JSON.parse(tokensStringify);

      setIsAuthenticated(Boolean(tokens.access_token));
    })();
  }, [token]);

  useEffect(() => {
    (async () => {
      const foquzId = await api.getFoquz();
      const newElement = document.createElement('div');
      newElement.innerHTML = `<a
      class="foquz-widget"
      data-text=""
      data-mode="quiz"
      data-button-view="icon"
      data-position="fixed"
      data-view="button"
      data-params='{"quiz":"${foquzId}","simpleView":1}'
      data-style='{"font":"Arial, Helvetica, sans-serif","fontSize":"14","bold":true,"italic":false,"color":"transparent","background":"transparent","border":false}'
      data-feedback="{}"
    ></a>
    `;
      document.body.insertAdjacentElement('beforeend', newElement);
      window?.initFoquz?.();
      window.parent.postMessage(
        {
          id: '1',
          source: 'foquz',
          type: 'fz:size',
          params: {
            size: {
              width: 0,
              height: 0,
            },
          },
        },
        '*'
      );

      // window.addEventListener('message', (event) => {
      //   console.log(event.data?.payload?.event);
      // });
    })();
  }, []);

  return (
    <InitialContext.Provider
      value={{ isAuthenticated, isRequiredUpdate, updateLink, currentPlatform, currentDeviceInfo }}
    >
      {isGetTokensCompleted &&
        isGetBackUrlCompleted &&
        isGetVersionCompleted &&
        isGetPlatformCompleted &&
        isGetDeviceInfo &&
        children}

      {!isGetTokensCompleted &&
        !isGetBackUrlCompleted &&
        !isGetVersionCompleted &&
        !isGetPlatformCompleted &&
        !isGetDeviceInfo && <SplashScreen />}
    </InitialContext.Provider>
  );
};

export { InitialContext, InitialProvider };
