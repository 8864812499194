import { Divider, Popover, Stack } from '@mui/material';
import { format, isThisYear, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Button, Typography } from 'front-package-ui-kit';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IUserActivity, IWorkoutDetailse } from '../../../../api/types';
import {
  BottomButtonBlock,
  BottomSheetHeader,
  BottomSheetUI,
  SkeletonWorkoutCard,
  WorkoutList,
} from '../../../../components';
import { useAnalytics, useAppDispatch, useAppSelector, useBottomSheet, useLoadingBottomSheet } from '../../../../hooks';
import { deleteUserActivityThunk } from '../../../../redux/userActivities/thunks';
import { deleteWorkoutFromHistoryThunk } from '../../../../redux/workouts/thunks';
import { ACTIVITY_DATE_FORMATE, Action, ActivityNavAction, AnalyticsName, Paths } from '../../../../utils';
import { setCurrntActivity } from '../../../CreateActivity/slice';
import { UserActivityCard } from '../UserActivityCard';
import WorkoutCard from '../WorkoutCard';

import s from './styles.module.scss';

export const openActivityBottomSheet = 'openActivityBottomSheet';

export const TEST_ID = 'ActivityBottomSheet';

const { profile, createActivity } = Paths;

interface IProps {
  storyWorkouts: IWorkoutDetailse[];
  userActivities: IUserActivity[];
}

const ActivityBottomSheet: FC<IProps> = ({ storyWorkouts, userActivities }) => {
  const { setAnalytics } = useAnalytics();

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const activityDay = useAppSelector((state) => state.profileStore.activityDay);

  const { getIsOpenBottomSheet, onCloseBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openActivityBottomSheet);

  const textDate = useMemo(() => {
    return isThisYear(activityDay)
      ? format(activityDay, 'd MMMM', { locale: ru })
      : format(activityDay, 'd MMMM yyyy г.', { locale: ru });
  }, [activityDay]);

  const { isLoading, setLoadingHandler } = useLoadingBottomSheet();

  const handleClose = useCallback(() => {
    onCloseBottomSheet();
  }, [onCloseBottomSheet]);

  const userActivitiesByDate = useMemo(() => {
    return userActivities.filter(
      (activity) =>
        format(parseISO(activity.date ?? ''), ACTIVITY_DATE_FORMATE) === format(activityDay, ACTIVITY_DATE_FORMATE)
    );
  }, [activityDay, userActivities]);

  const workoutsByActivityDate = useMemo(() => {
    return storyWorkouts.filter(
      (workout) =>
        format(parseISO(workout.progress?.date ?? ''), ACTIVITY_DATE_FORMATE) ===
        format(activityDay, ACTIVITY_DATE_FORMATE)
    );
  }, [activityDay, storyWorkouts]);

  const handleCreateActivity = useCallback(
    () => navigate(`${createActivity}/${ActivityNavAction.create}/${activityDay}`),
    [activityDay, navigate]
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [currentActivity, setCurrentActivity] = useState<null | IUserActivity>(null);

  const open = Boolean(anchorEl);

  const handleClosePopover = () => {
    setCurrentActivity(null);

    setAnchorEl(null);
  };

  const id = open ? 'simple-popover' : undefined;

  const handleDeleteUserActivity = useCallback(() => {
    if (!currentActivity) return;

    setAnalytics(AnalyticsName.activeDay, {
      action: Action.remove,
      pathname,
      minutes: currentActivity.minutes,
      date: currentActivity.date,
    });

    handleClosePopover();

    dispatch(deleteUserActivityThunk(currentActivity));
  }, [currentActivity, dispatch, pathname, setAnalytics]);

  const handleEditUserActivity = useCallback(() => {
    if (!currentActivity) return;

    dispatch(setCurrntActivity(currentActivity));

    navigate(`${createActivity}/${ActivityNavAction.edit}/0`);
  }, [currentActivity, dispatch, navigate]);

  const handleDeleteWorkout = useCallback(
    (workout: IWorkoutDetailse) => () => {
      if (!workout.progress) return;

      dispatch(
        deleteWorkoutFromHistoryThunk({
          workoutProgressId: workout.progress.id,
          courseProgressId: workout?.course?.progress?.id ?? workout.progress.id,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      onSpring={setLoadingHandler}
      onDismiss={handleClose}
      header={
        <BottomSheetHeader
          alignItems="center"
          title={
            <Typography variant="text3" textAlign="center" fontWeight={700}>
              Активность
            </Typography>
          }
          onClose={onCloseBottomSheet}
        />
      }
      footer={
        <BottomButtonBlock direction="column" rowGap="8px">
          <Button size="L" onClick={handleCreateActivity} color="black" variant="contained" type="submit" fullWidth>
            <Typography variant="text6" color="white" fontWeight={700}>
              Добавить активность
            </Typography>
          </Button>

          <Button size="L" onClick={handleClose} color="black" variant="text" type="submit" fullWidth>
            <Typography variant="text6" color="black" fontWeight={700}>
              Закрыть
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <Stack className={s.activityBottomSheet} rowGap="16px">
        <Typography variant="text7" opacity={0.6}>
          {textDate}
        </Typography>

        <div>
          {!!workoutsByActivityDate.length && (
            <WorkoutList
              options={workoutsByActivityDate}
              renderCard={(workout) => (
                <WorkoutCard
                  key={`${workout.id}-${workout.progress?.status}-${workout.progress?.date}`}
                  workout={workout}
                  type="Menu"
                  onDelete={handleDeleteWorkout(workout)}
                />
              )}
              skeletons={workoutsByActivityDate.length}
              loading={isLoading}
            />
          )}

          <Stack rowGap="12px">
            {!!userActivitiesByDate.length && !isLoading && (
              <>
                {userActivitiesByDate.map((activity) => (
                  <UserActivityCard
                    key={activity.id}
                    activity={activity}
                    withIcon
                    setCurrentActivity={setCurrentActivity}
                    setAnchorEl={setAnchorEl}
                  />
                ))}

                <Popover
                  id={id}
                  open={open}
                  style={{ zIndex: 99999 }}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Stack px="14px">
                    <button className={s.popoverBtn} onClick={handleEditUserActivity}>
                      <Typography variant="text5" textAlign="left">
                        Редактировать
                      </Typography>
                    </button>

                    <Divider />

                    <button className={s.popoverBtn} onClick={handleDeleteUserActivity}>
                      <Typography variant="text5" textAlign="left">
                        Удалить
                      </Typography>
                    </button>
                  </Stack>
                </Popover>
              </>
            )}

            {isLoading &&
              [...Array(userActivitiesByDate.length).keys()].map((_, i) => <SkeletonWorkoutCard size="S" key={i} />)}
          </Stack>
        </div>
      </Stack>
    </BottomSheetUI>
  );
};

export default memo(ActivityBottomSheet);
