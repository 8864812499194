import { Box, Stack } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import s from './styles.module.scss';
import { BadgeCounter, Typography } from 'front-package-ui-kit';
import { IMobileNavigationIconProps } from '../../../../../../models';
import { useNotifications } from '../../../../../../hooks';
import { Paths } from '../../../../../../utils';

interface IProps {
  path: string;
  Icon: React.FC<IMobileNavigationIconProps>;
  label: React.ReactNode;
  isActive: boolean;
  theme?: 'light' | 'dark';
}

const MobileNavigationAction: FC<IProps> = ({ Icon, path, isActive, label, theme = 'light' }) => {
  // const [currentPath, setCurrentPath] = useState(path);

  // const { pathname } = useLocation();

  const { isHaveUnreadNotifications, titleCountOfUnreadNotifications } = useNotifications();

  const navigate = useNavigate();

  const isBadge = path === Paths.profile && isHaveUnreadNotifications;

  const onClickHandler = () => {
    if (isActive) return;

    navigate(path);
  };

  // useEffect(() => {
  //   if (isActive) setCurrentPath(pathname);
  // }, [isActive, pathname]);

  return (
    <Stack
      component="button"
      onClick={onClickHandler}
      direction="column"
      alignItems="center"
      width="100%"
      bgcolor="transparent"
    >
      <Stack direction="column" alignItems="center" justifyContent="center" className={s.iconContainer}>
        <Icon isActive={isActive} theme={theme} />

        {isBadge && (
          <Box position="absolute" minWidth="16px" height="16px" top="-2px" left="30px">
            <BadgeCounter color="lime">{titleCountOfUnreadNotifications}</BadgeCounter>
          </Box>
        )}
      </Stack>

      <Typography
        variant="text8"
        fontWeight={500}
        opacity={isActive ? 1 : 0.5}
        color={theme === 'light' ? 'black' : 'white'}
      >
        {label}
      </Typography>
    </Stack>
  );
};

export default MobileNavigationAction;
