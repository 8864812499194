import { configureStore, ThunkAction, Action, combineReducers, Reducer, createAction } from '@reduxjs/toolkit';
import authReducer from './auth/slice';
import coursesReducer from './courses/slice';
import workoutsReducer from './workouts/slice';
import userReducer from './user/slice';
import ratingReducer from './rating/slice';
import themeReducer from './theme/slice';
import trainerReducer from './trainer/slice';
import notificationsReducer from './notifications/slice';
import registrationReducer from './registration/slice';
import filtersReducer from './filters/slice';
import groupsReducer from './groups/slice';
import countriesReducer from './countries/slice';
import socialsReducer from './socials/slice';
import parametersReducer from './parameters/slice';
import imageReducer from './images/slice';
import orderReducer from './order/slice';
import dictionariesReducer from './dictionaries/slice';
import userAgreementsReducer from './userAgreements/slice';
import gradeReducer from './grade/slice';
import promoBannersReducer from './promoBanners/slice';
import renderSchemesReducer from './renderSchemes/slice';
import referralReducer from './referral/slice';
import commentsReducer from './comments/slice';
import profileReducer from '../pages/Profile/slice';
import userActivitiesReducer from './userActivities/slice';
import createActivityReducer from '../pages/CreateActivity/slice';
import scrollSaveReducer from './scrollSave/slice';
import coursesPageReducer from '../pages/Courses/slice';
import courseVideosPageReducer from '../pages/CourseVideos/slice';
import dashBoardSizesReducer from '../components/organisms/DashBoard/components/DashBoardSizes/slice';
import manageSubscribePageReducer from '../pages/ManageSubscribe/slice';
import bannerOnMainReducer from './bannerOnMain/slice';
import paymentReducer from './payment/slice';
import storyReducer from '../pages/Stories/model/slice';
import sortReducer from './sort/slice';
import singleWorkoutsPageReducer from '../pages/SingleWorkoutsAll/slice';
import serverConectionReducer from './serverConection/slice';
import deviceReducer from './device/slice';

const combineReducer = combineReducers({
  authStore: authReducer,
  coursesStore: coursesReducer,
  filtersStore: filtersReducer,
  workoutsStore: workoutsReducer,
  userStore: userReducer,
  ratingStore: ratingReducer,
  trainerStore: trainerReducer,
  themeStore: themeReducer,
  notificationsStore: notificationsReducer,
  registrationStore: registrationReducer,
  groupsStore: groupsReducer,
  countriesStore: countriesReducer,
  socialsStore: socialsReducer,
  parametersStore: parametersReducer,
  orderStore: orderReducer,
  imageStore: imageReducer,
  dictionariesStore: dictionariesReducer,
  userAgreementsStore: userAgreementsReducer,
  gradeStore: gradeReducer,
  promoBannersStore: promoBannersReducer,
  renderSchemesStore: renderSchemesReducer,
  referralStore: referralReducer,
  commentStore: commentsReducer,
  profileStore: profileReducer,
  userActivityStore: userActivitiesReducer,
  createActivityStore: createActivityReducer,
  scrollSaveStore: scrollSaveReducer,
  coursesPageStore: coursesPageReducer,
  courseVideosPageStore: courseVideosPageReducer,
  dashBoardSizesStore: dashBoardSizesReducer,
  manageSubscribePageStore: manageSubscribePageReducer,
  bannerOnMainStore: bannerOnMainReducer,
  paymentStore: paymentReducer,
  storyStore: storyReducer,
  sortStore: sortReducer,
  singleWorkoutsPageStore: singleWorkoutsPageReducer,
  serverConectionStore: serverConectionReducer,
  deviceStore: deviceReducer,
});

const REMOVE_STORE_ACTION_TYPE = 'removeStore';

const rootReducer: Reducer = (state: RootState, action: Action) => {
  let copyState = { ...state };

  let copyAction = { ...action };

  if (action.type === REMOVE_STORE_ACTION_TYPE) {
    copyState = {} as RootState;

    copyAction = { type: '' };
  }

  return combineReducer(copyState, copyAction);
};

export const store = configureStore({
  reducer: rootReducer,
});

export const removeStore = createAction(REMOVE_STORE_ACTION_TYPE);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combineReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
