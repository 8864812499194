import { FC } from 'react';

interface IProps {
  active?: boolean;
}

export const FavoritesIcon: FC<IProps> = ({ active }) => {
  return (
    <>
      {active && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.38992 15.2434C8.15118 15.404 7.84882 15.404 7.61008 15.2434C6.00595 14.1643 0 9.85223 0 5.77922C0 0.435224 6.28 -0.420776 8 3.17922C9.72 -0.420776 16 0.435224 16 5.77922C16 9.85223 9.99405 14.1643 8.38992 15.2434Z"
            fill="#1D1D1D"
          />
        </svg>
      )}

      {!active && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.50886 15.3063C13.448 12.3867 16 9.2676 16 5.88989C16 1.10336 10.7097 -0.47351 8 2.51628C5.29025 -0.47351 0 1.10336 0 5.88989C0 9.2676 2.55201 12.3867 7.49114 15.3063C7.80501 15.4918 8.19499 15.4918 8.50886 15.3063ZM8.91102 4.7467C10.1876 1.92637 14 2.6648 14 5.88989C14 8.30354 12.0487 10.7836 8 13.2776C3.95128 10.7836 2 8.30354 2 5.88989C2 2.6648 5.81241 1.92637 7.08898 4.7467C7.44363 5.53022 8.55637 5.53022 8.91102 4.7467Z"
            fill="#1D1D1D"
          />
        </svg>
      )}
    </>
  );
};
