import { FC } from 'react';

interface IProps {
  active?: boolean;
}

export const CoursesIcon: FC<IProps> = ({ active }) => {
  return (
    <>
      {active && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4ZM4.00293 6.7619C4.00293 6.34112 4.34405 6 4.76483 6H11.2381C11.6589 6 12 6.34112 12 6.7619C12 7.18269 11.6589 7.52381 11.2381 7.52381H4.76483C4.34405 7.52381 4.00293 7.18269 4.00293 6.7619ZM4.76483 9.04761C4.34405 9.04761 4.00293 9.38872 4.00293 9.80951C4.00293 10.2303 4.34405 10.5714 4.76483 10.5714H11.2381C11.6589 10.5714 12 10.2303 12 9.80951C12 9.38872 11.6589 9.04761 11.2381 9.04761H4.76483Z"
            fill="#1D1D1D"
          />
        </svg>
      )}

      {!active && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2H4C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V4C14 2.89543 13.1046 2 12 2ZM4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4Z"
            fill="#1D1D1D"
          />
          <rect x="4.5" y="6" width="7" height="1.52381" rx="0.761905" fill="#1D1D1D" />
          <rect x="4.5" y="9.04761" width="7" height="1.52381" rx="0.761905" fill="#1D1D1D" />
        </svg>
      )}
    </>
  );
};
