import React from 'react';
import { FC } from 'react';
import { IMobileNavigationIconProps } from '../../../models';

interface IProps extends IMobileNavigationIconProps {}

const CoursesIcon: FC<IProps> = ({ isActive, theme = 'light' }) => {
  return (
    <>
      {!isActive && (
        <>
          {theme === 'light' && (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.04248 8.95044C7.4902 8.95044 7.04248 9.39816 7.04248 9.95044C7.04248 10.5027 7.4902 10.9504 8.04248 10.9504H15.8896C16.4419 10.9504 16.8896 10.5027 16.8896 9.95044C16.8896 9.39816 16.4419 8.95044 15.8896 8.95044H8.04248Z"
                fill="#818181"
              />
              <path
                d="M8.04248 13C7.4902 13 7.04248 13.4477 7.04248 14C7.04248 14.5523 7.4902 15 8.04248 15H15.8896C16.4419 15 16.8896 14.5523 16.8896 14C16.8896 13.4477 16.4419 13 15.8896 13H8.04248Z"
                fill="#818181"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1002 0.666016L7.74389 0.670363L7.73773 0.670382C6.01344 0.681003 4.5255 1.2595 3.4699 2.35627C2.41802 3.44919 1.88672 4.96154 1.88672 6.67317V16.6679C1.88672 18.3876 2.42187 19.907 3.48243 21.0019C4.54679 22.1008 6.04661 22.6751 7.7841 22.6751L16.141 22.6718L16.1471 22.6718C17.8715 22.6612 19.3597 22.0823 20.4155 20.9854C21.4676 19.8923 21.9992 18.3797 21.9992 16.6679V6.67317C21.9992 4.95337 21.4637 3.43397 20.4029 2.33906C19.3382 1.24029 17.8377 0.666016 16.1002 0.666016ZM16.1011 2.66602L7.74736 2.67036C6.47328 2.67879 5.53353 3.09626 4.91091 3.74317C4.28414 4.39439 3.88672 5.37421 3.88672 6.67317V16.6679C3.88672 17.9741 4.28735 18.9583 4.91903 19.6105C5.54686 20.2586 6.49564 20.675 7.78381 20.6751L16.1348 20.6718L16.1376 20.6718C17.4115 20.6634 18.3515 20.2457 18.9745 19.5985C19.6016 18.9469 19.9992 17.9667 19.9992 16.6679V6.67317C19.9992 5.36717 19.5984 4.38299 18.9665 3.73076C18.3384 3.08249 17.3892 2.66611 16.1011 2.66602Z"
                fill="#818181"
              />
            </svg>
          )}

          {theme === 'dark' && (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.04248 8.95044C7.4902 8.95044 7.04248 9.39816 7.04248 9.95044C7.04248 10.5027 7.4902 10.9504 8.04248 10.9504H15.8896C16.4419 10.9504 16.8896 10.5027 16.8896 9.95044C16.8896 9.39816 16.4419 8.95044 15.8896 8.95044H8.04248Z"
                fill="#818181"
              />
              <path
                d="M8.04248 13C7.4902 13 7.04248 13.4477 7.04248 14C7.04248 14.5523 7.4902 15 8.04248 15H15.8896C16.4419 15 16.8896 14.5523 16.8896 14C16.8896 13.4477 16.4419 13 15.8896 13H8.04248Z"
                fill="#818181"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1002 0.666016L7.74389 0.670363L7.73773 0.670382C6.01344 0.681003 4.5255 1.2595 3.4699 2.35627C2.41802 3.44919 1.88672 4.96154 1.88672 6.67317V16.6679C1.88672 18.3876 2.42187 19.907 3.48243 21.0019C4.54679 22.1008 6.04661 22.6751 7.7841 22.6751L16.141 22.6718L16.1471 22.6718C17.8715 22.6612 19.3597 22.0823 20.4155 20.9854C21.4676 19.8923 21.9992 18.3797 21.9992 16.6679V6.67317C21.9992 4.95337 21.4637 3.43397 20.4029 2.33906C19.3382 1.24029 17.8377 0.666016 16.1002 0.666016ZM16.1011 2.66602L7.74736 2.67036C6.47328 2.67879 5.53353 3.09626 4.91091 3.74317C4.28414 4.39439 3.88672 5.37421 3.88672 6.67317V16.6679C3.88672 17.9741 4.28735 18.9583 4.91903 19.6105C5.54686 20.2586 6.49564 20.675 7.78381 20.6751L16.1348 20.6718L16.1376 20.6718C17.4115 20.6634 18.3515 20.2457 18.9745 19.5985C19.6016 18.9469 19.9992 17.9667 19.9992 16.6679V6.67317C19.9992 5.36717 19.5984 4.38299 18.9665 3.73076C18.3384 3.08249 17.3892 2.66611 16.1011 2.66602Z"
                fill="#818181"
              />
            </svg>
          )}
        </>
      )}

      {isActive && (
        <>
          {theme === 'light' && (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.91091 3.74317C5.53353 3.09626 6.47328 2.67879 7.74736 2.67036L16.1011 2.66602C17.3892 2.66611 18.3384 3.08249 18.9665 3.73076C19.5984 4.38299 19.9992 5.36717 19.9992 6.67317V16.6679C19.9992 17.9667 19.6016 18.9469 18.9745 19.5985C18.3515 20.2457 17.4115 20.6634 16.1376 20.6718L16.1348 20.6718L7.78381 20.6751C6.49564 20.675 5.54686 20.2586 4.91903 19.6105C4.28735 18.9583 3.88672 17.9741 3.88672 16.6679V6.67317C3.88672 5.37421 4.28414 4.39439 4.91091 3.74317ZM7.04248 7.21994C7.04248 6.66766 7.4902 6.21994 8.04248 6.21994H11.0368C11.5891 6.21994 12.0368 6.66766 12.0368 7.21994C12.0368 7.77223 11.5891 8.21994 11.0368 8.21994H8.04248C7.4902 8.21994 7.04248 7.77223 7.04248 7.21994ZM8.04248 10.7592C7.4902 10.7592 7.04248 11.207 7.04248 11.7592C7.04248 12.3115 7.4902 12.7592 8.04248 12.7592H15.8896C16.4419 12.7592 16.8896 12.3115 16.8896 11.7592C16.8896 11.207 16.4419 10.7592 15.8896 10.7592H8.04248ZM8.04248 15.3088C7.4902 15.3088 7.04248 15.7565 7.04248 16.3088C7.04248 16.8611 7.4902 17.3088 8.04248 17.3088H15.8896C16.4419 17.3088 16.8896 16.8611 16.8896 16.3088C16.8896 15.7565 16.4419 15.3088 15.8896 15.3088H8.04248Z"
                fill="#1D1D1D"
              />
            </svg>
          )}

          {theme === 'dark' && (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.91091 3.74317C5.53353 3.09626 6.47328 2.67879 7.74736 2.67036L16.1011 2.66602C17.3892 2.66611 18.3384 3.08249 18.9665 3.73076C19.5984 4.38299 19.9992 5.36717 19.9992 6.67317V16.6679C19.9992 17.9667 19.6016 18.9469 18.9745 19.5985C18.3515 20.2457 17.4115 20.6634 16.1376 20.6718L16.1348 20.6718L7.78381 20.6751C6.49564 20.675 5.54686 20.2586 4.91903 19.6105C4.28735 18.9583 3.88672 17.9741 3.88672 16.6679V6.67317C3.88672 5.37421 4.28414 4.39439 4.91091 3.74317ZM7.04248 7.21994C7.04248 6.66766 7.4902 6.21994 8.04248 6.21994H11.0368C11.5891 6.21994 12.0368 6.66766 12.0368 7.21994C12.0368 7.77223 11.5891 8.21994 11.0368 8.21994H8.04248C7.4902 8.21994 7.04248 7.77223 7.04248 7.21994ZM8.04248 10.7592C7.4902 10.7592 7.04248 11.207 7.04248 11.7592C7.04248 12.3115 7.4902 12.7592 8.04248 12.7592H15.8896C16.4419 12.7592 16.8896 12.3115 16.8896 11.7592C16.8896 11.207 16.4419 10.7592 15.8896 10.7592H8.04248ZM8.04248 15.3088C7.4902 15.3088 7.04248 15.7565 7.04248 16.3088C7.04248 16.8611 7.4902 17.3088 8.04248 17.3088H15.8896C16.4419 17.3088 16.8896 16.8611 16.8896 16.3088C16.8896 15.7565 16.4419 15.3088 15.8896 15.3088H8.04248Z"
                fill="white"
              />
            </svg>
          )}
        </>
      )}
    </>
  );
};

export default CoursesIcon;
